import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import AccountBox from "@mui/icons-material/AccountBox";
import axios from "axios";
import { apiURL } from "../../app/config";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import TermsModal from "../../components/TermsModal";

const Register = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [passwordError, setPasswordError] = useState("");
  const [userfromAPI, setUserfromAPI] = useState({
    fullName: "",
  });
  const [formData, setFormData] = useState({
    membershipNumber: "",
    membershipNumbererror: false,
    personalInfo: "",
    password: "",
    confirmPassword: "",
    nationalID: "",
    request_id: "",
    fullName: "",
    birthdate: "",
    OTP: "",
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setIsChecked(true);
    setIsModalOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Update formData state with new value and reset error state for membershipNumber
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      membershipNumbererror: false,
    }));

    // Prevent invalid inputs for membershipNumber and nationalID
    if (name === "membershipNumber" && (isNaN(value) || value.length > 5)) {
      return;
    }
    if (name === "nationalID" && (isNaN(value) || value.length > 13)) {
      return;
    }

    // Check membership number and nationalID
    if (name === "nationalID" && (isNaN(value) || value.length === 13)) {
      try {
        const response = await axios.post(
          `${apiURL}auth/checkMembershipNumberGetname`,
          {
            membershipNumber: formData.membershipNumber,
            idcard: value,
          }
        );
        if (response.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            fullName: response.data.data.MemName,
            [name]: value,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            fullName: "",
            membershipNumbererror: true,
          }));
        }
      } catch (error) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          fullName: "",
          membershipNumbererror: true,
        }));
        console.error("Error submitting the form:", error);
      }
    } else {
      // Update formData state with new value if it's not nationalID or membershipNumber
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // Clear password error if password or confirmPassword fields are being updated
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const checkMembershipNumber = async () => {
    try {
      const response = await axios.post(`${apiURL}auth/checkMembershipNumber`, {
        membershipNumber: formData.membershipNumber,
      });
      if (response.status === 200) {
        handleNext();
      } else {
        console.log("username invalid");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const fetchOTP = async () => {
    try {
      const response = await axios.post(`${apiURL}auth/getOTP`, {
        username: formData.membershipNumber,
        idcard: formData.nationalID,
      });
      if (response.status === 200) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          request_id: response.data.data.request_id,
        }));
      } else {
        console.log("Error fetching OTP");
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
    }
  };

  const checkOTP = async () => {
    try {
      const response = await axios.post(`${apiURL}auth/checkotp`, {
        username: formData.membershipNumber,
        idcard: formData.nationalID,
        request_id: formData.request_id,
        OTP_Update: formData.OTP,
        deviceID: "wab",
      });
      if (response.status === 200) {
        handleNext();
      } else {
        console.log("Error checking OTP");
      }
    } catch (error) {
      console.error("Error checking OTP:", error);
    }
  };

  const addPassword = async () => {
    try {
      const response = await axios.post(`${apiURL}auth/addpassword`, {
        username: formData.membershipNumber,
        idcard: formData.nationalID,
        request_id: formData.request_id,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });
      if (response.status === 200) {
        setUserfromAPI(response.data.data);
        setActiveStep(3);
      }
    } catch (error) {
      console.error("Error adding password:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activeStep === 2) {
      if (!validateConfirmPassword(formData.confirmPassword)) {
        setPasswordError("รหัสผ่านไม่ตรงกัน");
        return;
      }
      addPassword();
    } else if (activeStep === 0) {
      checkMembershipNumber();
    } else if (activeStep === 1) {
      checkOTP();
    }
  };

  const validateNationalID = (id) => {
    const regex = /^\d{13}$/;
    return regex.test(id);
  };

  const validateConfirmPassword = (confirmPassword) => {
    return formData.password === confirmPassword;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "#b8f6ff",
        p: 5,
        m: 2,
        borderRadius: 5,
      }}
    >
      <Box
        sx={{
          width: "80%",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#37E4FF",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: { xs: 200, lg: 300 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <img
            src="/LogoICT.png"
            alt="Coop Logo"
            style={{
              borderRadius: "50%",
              width: "6rem",
            }}
          />
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย​ ไอซีที จำกัด
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" align="center" gutterBottom>
            ระบบตรวจสอบข้อมูลสมาชิก Online
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            ลงทะเบียนสมาชิก (register)
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
              <StepLabel
                StepIconProps={{
                  sx: { fontSize: "20px" },
                }}
                sx={{ "& .MuiStepLabel-label": { fontSize: "20px" } }}
              >
                ตรวจสอบเลขทะเบียนสมาชิก
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                StepIconProps={{
                  sx: { fontSize: "20px" },
                }}
                sx={{ "& .MuiStepLabel-label": { fontSize: "20px" } }}
              >
                ตรวจสอบข้อมูลส่วนตัว
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                StepIconProps={{
                  sx: { fontSize: "20px" },
                }}
                sx={{ "& .MuiStepLabel-label": { fontSize: "20px" } }}
              >
                ยืนยันรับรหัสผ่าน
              </StepLabel>
            </Step>
          </Stepper>
          <form onSubmit={handleSubmit}>
            {activeStep === 0 && (
              <Box className="w-full text-center block p-2">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6" align="center">
                    เลขทะเบียนสมาชิกสหกรณ์ฯ
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    required
                    id="membershipNumber"
                    name="membershipNumber"
                    placeholder="เลขทะเบียนสมาชิก"
                    value={formData.membershipNumber}
                    onChange={handleChange}
                    margin="normal"
                    sx={{ width: "450px" }}
                    InputProps={{
                      startAdornment: (
                        <AccountBox
                          sx={{
                            color: "gray",
                          }}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <span className="text-xs xl:text-base text-gray-600 lg:text-base md:text-base font-light text-center">
                    กรุณากรอกหมายเลขสมาชิกให้ครบทุกหลัก เช่น 12345
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 2,
                      p: 2,
                      bgcolor: "transparent",
                      width: "3/6",
                      md: { width: "1/4" },
                      lg: { width: "1/4" },
                      xl: { width: "1/4" },
                      backgroundImage:
                        "linear-gradient(to right, #B645CA, #B1073F)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to right, #B645CA, #B1073F)",
                        boxShadow:
                          "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                        transform: "translateY(-1px)",
                        transition: "all 0.3s ease-in-out",
                      },
                    }}
                  >
                    ตรวจสอบ
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <TextField
                  required
                  id="nationalID"
                  name="nationalID"
                  label="เลขที่บัตรประชาชน"
                  placeholder="เลขที่บัตรประชาชน"
                  value={formData.nationalID}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  maxRows={12}
                  error={
                    !validateNationalID(formData.nationalID) ||
                    formData.membershipNumbererror
                  }
                  helperText={
                    !validateNationalID(formData.nationalID)
                      ? "กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง"
                      : ""
                  }
                />
                <TextField
                  disabled
                  required
                  id="fullName"
                  name="fullName"
                  label="ชื่อ นามสกุล"
                  placeholder="ชื่อ นามสกุล"
                  value={formData.fullName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  required
                  id="birthdate"
                  name="birthdate"
                  label="วัน/เดือน/ปีเกิด"
                  type="date"
                  value={formData.birthdate}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  disabled={
                    !formData.nationalID ||
                    !formData.fullName ||
                    !formData.birthdate
                  }
                  variant="contained"
                  sx={{
                    mt: 2,
                    bgcolor: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #B645CA, #B1073F)",
                    "&:hover": {
                      backgroundImage:
                        "linear-gradient(to right, #B645CA, #B1073F)",
                      boxShadow:
                        "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                      transform: "translateY(-1px)",
                      transition: "all 0.3s ease-in-out",
                    },
                    color: "#ffffff",
                  }}
                  onClick={fetchOTP}
                >
                  รับ OTP
                </Button>

                <TextField
                  required
                  id="OTP"
                  name="OTP"
                  label="OTP"
                  placeholder="OTP"
                  value={formData.OTP}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 2,
                      p: 2,
                      bgcolor: "transparent",
                      width: "3/6",
                      md: { width: "1/4" },
                      lg: { width: "1/4" },
                      xl: { width: "1/4" },
                      backgroundImage:
                        "linear-gradient(to right, #B645CA, #B1073F)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to right, #B645CA, #B1073F)",
                        boxShadow:
                          "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                        transform: "translateY(-1px)",
                        transition: "all 0.3s ease-in-out",
                      },
                    }}
                  >
                    ตรวจสอบ
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="ตั้งรหัสผ่าน"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <TextField
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!validateConfirmPassword(formData.confirmPassword)}
                    helperText={
                      !validateConfirmPassword(formData.confirmPassword)
                        ? "รหัสผ่านไม่ตรงกัน"
                        : ""
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 2,
                      p: 2,
                      bgcolor: "transparent",
                      width: "3/6",
                      md: { width: "1/4" },
                      lg: { width: "1/4" },
                      xl: { width: "1/4" },
                      backgroundImage:
                        "linear-gradient(to right, #B645CA, #B1073F)",
                      "&:hover": {
                        backgroundImage:
                          "linear-gradient(to right, #B645CA, #B1073F)",
                        boxShadow:
                          "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                        transform: "translateY(-1px)",
                        transition: "all 0.3s ease-in-out",
                      },
                    }}
                  >
                    ลงทะเบียน
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 3 && (
              <Box sx={{ textAlign: "center", p: 3 }}>
                <CheckCircleOutline
                  sx={{
                    fontSize: "100px",
                    color: "green",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
                <Typography variant="h6" gutterBottom>
                  การยืนยันครบทุกขั้นตอนแล้ว
                </Typography>
                <Typography variant="body1" gutterBottom>
                  ยินดีต้อนรับ
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    bgcolor: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #B645CA, #B1073F)",
                    "&:hover": {
                      backgroundImage:
                        "linear-gradient(to right, #B645CA, #B1073F)",
                      boxShadow:
                        "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                      transform: "translateY(-1px)",
                      transition: "all 0.3s ease-in-out",
                    },
                  }}
                  onClick={() => navigate("/login")}
                >
                  เริ่มใช้งาน
                </Button>
              </Box>
            )}
          </form>
          <hr />
          <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
            <Typography
              variant="body2"
              sx={{ cursor: "pointer", color: "blue", display: "inline" }}
              onClick={() => navigate("/login")}
            >
              เข้าสู่ระบบ (Login)
            </Typography>{" "}
            |
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                color: "blue",
                display: "inline",
                ml: 1,
              }}
              onClick={() => navigate("/reset-password")}
            >
              ลืมรหัสผ่าน (Reset Password)
            </Typography>
          </Typography>
        </Box>
      </Box>
      {isModalOpen ? (
        <TermsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Box>
  );
};

export default Register;
