import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { bahttext } from "bahttext";

import { NevComponent } from "../../components/NevComponent";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";
import { apiURL } from "../../app/config";

export default function Bill() {
  const replace = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [billDetails, setBillDetails] = useState([]);
  const [dataModalOpen, setDataModalOpen] = useState({});
  const [open, setOpen] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [detail, setDetail] = useState([]);
  const [headerData, setHeaderData] = useState(null);

  useEffect(() => {
    if (!authData) {
      replace("/login");
    } else {
      fetchBillData();
    }
  }, [authData, replace]);

  const fetchBillData = async () => {
    try {
      const response = await axios.get(
        `${apiURL}getBill/${authData.membershipNumber}`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => {
          let sum =
            item.PayLoanType === "0"
              ? parseFloat(item.PayOldBal) + parseFloat(item.TotalPayMonthAmt)
              : parseFloat(item.PayOldBal) > parseFloat(item.TotalPayMonthAmt)
              ? parseFloat(item.PayOldBal) - parseFloat(item.TotalPayMonthAmt)
              : parseFloat(item.TotalPayMonthAmt) - parseFloat(item.PayOldBal);

          return {
            payId: item.PayID,
            payMemId: item.PayMemID,
            payUpDate: formatThaiDate(item.PayUpDate),
            payUpDateOG: item.PayUpDate,
            ePayUpDate: formatThaiMonthYear(item.PayUpDate),
            payMonthAmt: formatNumberWithCommas(item.TotalPayMonthAmt),
            payMonthInt: formatNumberWithCommas(item.TotalPayMonthInt),
            payMonthSum:
              parseFloat(item.TotalPayMonthAmt) +
              parseFloat(item.TotalPayMonthInt),
            payOldBal: formatNumberWithCommas(item.PayOldBal),
            totalAmountText: item.TotalAmt,
            totalAmount: formatNumberWithCommas(sum),
            totalAmt: formatNumberWithCommas(item.TotalAmt),
          };
        });
        setBillDetails(formattedData);
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
    }
  };

  const fetchBillDetail = async (payUpDate) => {
    try {
      const response = await axios.get(
        `${apiURL}gatBillDetail/${authData.membershipNumber}?payUpDate=${payUpDate}`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        setDetail(data.data);
      }
    } catch (error) {
      console.error("Error fetching detailed bill data:", error);
    }
  };

  const fetchHeadBill = async (item) => {
    try {
      const response = await axios.get(
        `${apiURL}gatHeadBill/${authData.membershipNumber}?payUpDate=${item.payUpDateOG}`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        setHeaderData({
          ...item,
          getdata: data.data[0],
        });
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };

  const formatThaiDate = (dateString) => {
    const months = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    const yearOffset = 543;
    const year = parseInt(dateString.substring(0, 4)) + yearOffset;
    const month = months[parseInt(dateString.substring(4, 6)) - 1];
    const day = dateString.substring(6, 8);
    return `${day} ${month} ${year}`;
  };

  const formatThaiMonthYear = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const yearOffset = 543;
    const year = parseInt(dateString.substring(0, 4)) + yearOffset;
    const month = months[parseInt(dateString.substring(4, 6)) - 1];
    return `${month} ${year}`;
  };

  const handleOpen = (data) => {
    setDataModalOpen(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenReceipt = async (details) => {
    await fetchHeadBill(details);
    await fetchBillDetail(details.payUpDateOG);
    // setDetail(details);
    setOpenReceipt(true);
  };

  const handleCloseReceipt = () => {
    setOpenReceipt(false);
  };
  const formatDocNo = (docNo) => {
    const year = parseInt(docNo.substring(0, 4)) + 543;
    const part1 = docNo.substring(5, 6);
    const part2 = docNo.substring(6, 12);
    return `M${part1}-${part2}/${year}`;
  };
  const getPaymentDescription = (details) => {
    switch (details.PayLoanType) {
      case "0":
        return "ค่าหุ้น";
      case "1":
      case "2":
      case "3":
        const promNoPart1 = details.PayPromNo.substring(5, 10);
        const promNoPart2 = (
          parseInt(details.PayPromNo.substring(1, 4)) + 543
        ).toString();
        return `${details.PromCodeRate}-${promNoPart1}/${promNoPart2}`;
      case "7":
      case "8":
        return details.PayDesc;
      case "9":
        return details.PayPromNo;
      default:
        return "รายการไม่ระบุ";
    }
  };

  return (
    <NevComponent
      title="ใบเสร็จประจำเดือน"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ p: 3 }}>
                  {billDetails.map((details, index) => (
                    <Paper
                      key={index}
                      sx={{ p: 2, mb: 2, backgroundColor: "#e3f2fd" }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar
                            sx={{
                              width: 100,
                              height: 100,
                              backgroundColor: "#90caf9",
                            }}
                          >
                            <Typography variant="h6" color="textPrimary">
                              {details.ePayUpDate}
                            </Typography>
                          </Avatar>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">
                            เลขทะเบียนสมาชิก : {details.payMemId}
                          </Typography>
                          <Typography variant="body2">
                            รวมเงินที่เรียกเก็บ : {details.totalAmt} (บาท)
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} textAlign="right">
                          <Typography variant="body2">
                            วันที่เรียกเก็บ : {details.payUpDate}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ mt: 2 }}
                      >
                        <Grid item xs={6} sm={6}>
                          {/* <Typography variant="body2">
                            รายละเอียดการหักส่ง
                          </Typography>
                          <Button
                            variant="outlined"
                            startIcon={<SearchIcon />}
                            onClick={() => handleOpen(details)}
                          >
                            คลิกที่นี่
                          </Button> */}
                        </Grid>
                        <Grid item xs={6} sm={6} textAlign="right">
                          <Button
                            variant="outlined"
                            startIcon={<PrintIcon />}
                            color="success"
                            onClick={() => handleOpenReceipt(details)}
                          >
                            พิมพ์ใบเสร็จ
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>

          {open && (
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
              <DialogTitle>รายละเอียดการเรียกเก็บ</DialogTitle>
              <DialogContent>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>รายการ</TableCell>
                        <TableCell align="right">เงินต้น</TableCell>
                        <TableCell align="right">ดอกเบี้ย</TableCell>
                        <TableCell align="right">เงินเรียกเก็บ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billDetails.map((transaction, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {getPaymentDescription(transaction)}
                          </TableCell>
                          <TableCell align="right">
                            {transaction.payMonthAmt}
                          </TableCell>
                          <TableCell align="right">
                            {transaction.payMonthInt}
                          </TableCell>
                          <TableCell align="right">
                            {formatNumberWithCommas(transaction.payMonthSum)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          <strong>รวมชำระ:</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>
                            {formatNumberWithCommas(dataModalOpen.payMonthSum)}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  ปิด
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {openReceipt && headerData && detail && (
            <Dialog
              open={openReceipt}
              onClose={handleCloseReceipt}
              maxWidth="md"
              fullWidth
            >
              <Paper sx={{ p: 4, m: 2, border: "1px solid #000000" }}>
                <Box textAlign="center" mb={3}>
                  <Typography variant="h6">ใบเสร็จรับเงิน</Typography>
                  <Typography variant="h6">
                    ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย​ ไอซีที จำกัด
                  </Typography>
                </Box>

                <Box mb={3}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8}>
                      <img
                        src="/LogoICT.png"
                        alt="Coop Logo"
                        style={{
                          borderRadius: "50%",
                          width: "6rem",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="right">
                      <Typography>
                        วันที่: {formatThaiDate(headerData.getdata.PayUpDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography>
                        ได้รับเงินจาก: {headerData.getdata.Prename}{" "}
                        {headerData.getdata.MemName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="right">
                      <Typography>
                        ใบเสร็จเลขที่:{" "}
                        {formatDocNo(headerData.getdata.PayDocNo)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography>
                        หน่วยงาน: {headerData.getdata.DeptName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="right">
                      <Typography>
                        เลขทะเบียนสมาชิก: {headerData.getdata.PayMemID}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
          
                <Box mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography>
                        ทุนเรือนหุ้น:{" "}
                        {formatNumberWithCommas(
                          parseFloat(detail[0].PayMonthAmt) +
                            parseFloat(detail[0].PayMonthInt) +
                            parseFloat(headerData.getdata.PayOldShr)
                        )}{" "}
                        บาท
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="right">
                      <Typography>
                        ดอกเบี้ยสะสม:{" "}
                        {formatNumberWithCommas(
                          parseFloat(headerData.getdata.PayOldInt) +
                            parseFloat(headerData.getdata.TotalIntAmt)
                        )}{" "}
                        บาท
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              <div
                style={{
                  margin: "16px",
                  border: "1px solid #000000",
                  borderRadius: "15px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    opacity: "0.1",
                    zIndex: 0,
                  }}
                >
                  <img
                    src="/LogoICT.png"
                    alt="Logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div style={{ position: "relative", zIndex: 1 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>รายการชำระ</TableCell>
                          <TableCell align="center">งวดที่</TableCell>
                          <TableCell align="center">เงินต้น</TableCell>
                          <TableCell align="center">ดอกเบี้ย</TableCell>
                          <TableCell align="center">จำนวนเงิน</TableCell>
                          <TableCell align="center">คงเหลือ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody> 
                        {detail.length > 0
                          ? detail.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {getPaymentDescription(row)}
                                </TableCell>
                                <TableCell align="center">
                                  {row.PayPerd === 0 ? "" : row.PayPerd}
                                </TableCell>
                                <TableCell align="center">
                                  {formatNumberWithCommas(row.PayMonthAmt)}
                                </TableCell>
                                <TableCell align="center">
                                  {formatNumberWithCommas(row.PayMonthInt)}
                                </TableCell>
                                <TableCell align="center">
                                  {formatNumberWithCommas(
                                    parseFloat(row.PayMonthAmt) +
                                      parseFloat(row.PayMonthInt)
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {row.PayLoanType === "1"
                                    ? formatNumberWithCommas(
                                        parseFloat(
                                          row.PayOldBal - row.PayMonthAmt
                                        )
                                      )
                                    : ""}
                                </TableCell>
                              </TableRow>
                            ))
                          : "ไม่มีข้อมูล"}
                        <TableRow>
                          <TableCell colSpan={3} align="right">
                            - {bahttext(parseFloat(headerData.totalAmountText))}{" "}
                            -
                          </TableCell>
                          <TableCell align="right">
                            <strong>รวมเงิน:</strong>
                          </TableCell>
                          <TableCell align="center">
                            <strong>{headerData.totalAmt}</strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <Box textAlign="center" mt={5} mb={5}>
                <Typography mt={2}></Typography>
                <Typography>ผู้จัดการ</Typography>
                <Typography>
                  ใบเสร็จเงินนี้ประจำเดือนจะสมบูรณ์ ต่อเมื่อสหกรณ์ฯ
                  ได้รับเงินที่เรียกเก็บครบถ้วนแล้ว
                </Typography>
              </Box>

              <DialogActions>
                <Button onClick={handleCloseReceipt} color="primary">
                  บันทึก
                </Button>
                <Button onClick={handleCloseReceipt} color="primary">
                  ปิด
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      }
    />
  );
}
