import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Toolbar,
} from "@mui/material";
import { NevComponent } from "../../components/NevComponent";
import { apiURL } from "../../app/config";

export default function Beneficiary() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchBeneficiaryData();
    }
  }, [authData, navigate]);

  const fetchBeneficiaryData = async () => {
    try {
      const response = await axios.get(`${apiURL}getBeneficiary/${authData.membershipNumber}`);
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => ({
          name: item.HelpRecvName,
          relationship: item.HelpRecvRela,
          share: parseFloat(item.HelpPerc).toFixed(2),
        }));
        setBeneficiaryDetails(formattedData);
      }
    } catch (error) {
      console.error("Error fetching beneficiary data:", error);
    }
  };

  return (
    <NevComponent
      title="ผู้รับผลประโยชน์"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ backgroundColor: "#c5e1a5", p: 1 }}
                  >
                    ผู้รับผลประโยชน์
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#e8f5e9" }}>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>ชื่อ-สกุล</TableCell>
                          <TableCell>ความสัมพันธ์</TableCell>
                          <TableCell align="right">สัดส่วน (%)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {beneficiaryDetails.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.relationship}</TableCell>
                            <TableCell align="right">{row.share}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
