import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff, Lock, VpnKey } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import { NevComponent } from "../../components/NevComponent";

export default function ChangePasswordForm() {
  const replace = useNavigate();
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );

  useEffect(() => {
    if (!authData) {
      replace("/login");
    }
  }, [authData, replace]);

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const [error, setError] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => () => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://ict-app-rog5q.ondigitalocean.app/api/auth/changepassword", {
        username: authData.username,
        oldpassword: values.oldPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
      });

      if (response.status === 200) {
        alert("Password changed successfully");
        navigate("/login");
      } else {
        handleErrors(response);
      }
    } catch (error) {
      handleErrors(error.response);
    }
  };

  const handleErrors = (response) => {
    if (response.data.code === 400) {
      setError("Passwords do not match.");
    } else if (response.data.system_response?.code === 401) {
      setError("Old password is incorrect.");
    } else {
      setError("An unknown error occurred.");
    }
  };

  return (
    <NevComponent
      title="เปลี่ยนรหัสผ่านสมาชิก"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ p: 3 }}>
                  <Paper sx={{ p: 4, m: 2, border: "2px solid #f50057" }}>
                    <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                      <Lock /> เปลี่ยนรหัสผ่านสมาชิก
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="รหัสผ่านเดิม"
                            type={values.showOldPassword ? "text" : "password"}
                            value={values.oldPassword}
                            onChange={handleChange("oldPassword")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword(
                                      "showOldPassword"
                                    )}
                                    edge="end"
                                  >
                                    {values.showOldPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <VpnKey />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="รหัสผ่านใหม่"
                            type={values.showNewPassword ? "text" : "password"}
                            value={values.newPassword}
                            onChange={handleChange("newPassword")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword(
                                      "showNewPassword"
                                    )}
                                    edge="end"
                                  >
                                    {values.showNewPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="ยืนยันรหัสผ่านใหม่"
                            type={
                              values.showConfirmPassword ? "text" : "password"
                            }
                            value={values.confirmPassword}
                            onChange={handleChange("confirmPassword")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword(
                                      "showConfirmPassword"
                                    )}
                                    edge="end"
                                  >
                                    {values.showConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {error && (
                          <Grid item xs={12}>
                            <Typography color="error" align="center">
                              {error}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} textAlign="center">
                          <Button
                            variant="contained"
                            color="error"
                            type="submit"
                          >
                            เปลี่ยนรหัสผ่าน
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
