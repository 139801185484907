import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TermsModal = ({ isOpen, onClose, onConfirm }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirm = () => {
    if (checked) {
      onConfirm();
    } else {
      alert("Please accept the terms and conditions.");
    }
  };

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth="sm">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/LogoICT.png"
            alt="Coop Logo"
            style={{
              borderRadius: "50%",
              width: "6rem",
            }}
          />
        </Box>

        <Typography variant="h6" component="div" align="center">
          ระบบสมาชิก Online
        </Typography>
        <Typography variant="subtitle1" component="div" align="center">
          ข้อกำหนดและเงื่อนไขในการใช้งาน
        </Typography>
        <Typography variant="subtitle1" component="div" align="center">
          ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย​ ไอซีที จำกัด
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Box component="div">
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1" component="div" align="center">
                การสมัครใช้บริการ
              </Typography>

              <Typography variant="subtitle1" component="div" align="center">
                ข้อกำหนดและเงื่อนไขในการใช้งาน website
              </Typography>

              <Typography variant="subtitle1" component="div" align="center">
                สำหรับสมาชิก ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย​ ไอซีที จำกัด
              </Typography>

              <ol>
                <li>
                  website ของ ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย​ ไอซีที จำกัด
                  ("สหกรณ์") เป็นการให้บริการทางเครื่องช่วยอินเทอร์เน็ต
                  ผ่านระบบและอุปกรณ์เทคโนโลยีในรูปแบบของเว็บไซต์ที่สามารถเข้าถึงได้โดยการใช้โปรแกรม
                  Web browser ทั้งคอมพิวเตอร์, แท็บเล็ตและบนโทรศัพท์มือถือ
                  เพื่อลดน้อยความสะดวกให้สมาชิกของสหกรณ์ ("สมาชิก")
                  สามารถทำการตรวจสอบข้อมูลส่วนบุคคล
                  ธุรกรรมทางการเงินที่มีกับสหกรณ์
                </li>
                <li>
                  การเข้าใช้บางระบบข้อมูลสมาชิกจะต้องทำการสมัครเข้าใช้งานระบบและต้องเป็นสมาชิกของสหกรณ์
                  เท่านั้น
                </li>
                <li>
                  หากสหกรณ์พบสมาชิกรายใดเข้าใช้งานระบบแล้ว
                  โอนสิทธิไปให้ผู้อื่นใช้งานต่อ โดยทุจริตหรือไม่สุจริตก็ตาม
                  จะถือว่าการใช้งานนั้นมิชอบด้วยกฎหมายและมีสิทธิ์ยกเลิกการใช้งานระบบของสมาชิกท่านนั้นได้
                </li>
                <li>
                  เพื่อความปลอดภัยในข้อมูลสมาชิก
                  หากสหกรณ์พบว่ามีบุคคลแอบอ้างเข้ามาใช้งานบัญชีของท่าน
                  ในการเข้าสู่ระบบสหกรณ์จะยังคับบุคคลนั้นออกจากระบบ
                  โดยไม่ต้องแจ้งให้ทราบ
                </li>
                <li>
                  หากมีข้อมูลรายละเอียดของสมาชิกไม่ถูกต้องในระบบแล้วมีข้อสงสัยหรือต้องการทำการเปลี่ยนแปลงปรับปรุงข้อมูลให้สมาชิกติดต่อเจ้าหน้าที่เพื่อทำการแก้ไขปรับปรุงเปลี่ยนแปลงข้อมูลต่อไป
                </li>
                <li>
                  สมาชิกได้อ่านข้อกำหนดดังกล่าวและยินยอมปฏิบัติตามข้อกำหนดและเงื่อนไขที่สหกรณ์กำหนดไว้รวมถึงที่สหกรณ์จะได้กำหนดแก้ไขเพิ่มเติมหรือเปลี่ยนแปลงในภายภาคหน้าซึ่งให้ถือเป็นส่วนหนึ่งของเงื่อนไขและข้อกำหนดนี้ด้วยการยืนยันข้อมูล
                </li>
              </ol>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleCheckboxChange} />
              }
              label="ข้าพเจ้าได้อ่านและตกลงยินยอมตามรายละเอียดข้อตกลงและความยินยอมข้างต้น"
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!checked}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#6a1b9a", marginRight: "5px" }}
          >
            ยืนยัน
          </Button>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#6a1b3a" }}
          >
            ยกเลิก
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TermsModal;
