import { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PaymentsIcon from "@mui/icons-material/Payments";
import BallotIcon from "@mui/icons-material/Ballot";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DvrIcon from "@mui/icons-material/Dvr";
import LockIcon from "@mui/icons-material/Lock";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export function NevComponent({ title = "", component }) {
  const replace = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.clear();
    replace("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar>
          <img
            src="/LogoICT.png"
            alt="Logo"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Toolbar>

        <Typography variant="body2" align="center">
          ชุมนุมสหกรณ์​ออม​ทรัพ​ย์ไทย
        </Typography>
        <Typography variant="body2" align="center">
          ไอซีที จำกัด
        </Typography>
        <Divider />
        <Divider />
        <Typography variant="body2" align="center">
          เลขทะเบียนสมาชิก {authData.membershipNumber}
        </Typography>
        <Typography variant="body2" align="center">
          คุณ {authData.fullName}
        </Typography>

        <Divider />
        <Divider />
        <List component="nav">
          <ListItemButton component={RouterLink} to="/infouser">
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="ข้อมูลส่วนบุคคล" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/shareRegister">
            <ListItemIcon>
              <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText primary="ทะเบียนหุ้น" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/loan">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="เงินกู้" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/deposit">
            <ListItemIcon>
              <PaymentsIcon />
            </ListItemIcon>
            <ListItemText primary="เงินฝาก" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/collection">
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText primary="รายการเรียกเก็บ" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/guarantee">
            <ListItemIcon>
              <HandshakeIcon />
            </ListItemIcon>
            <ListItemText primary="ภาระค้ำประกัน" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/dividend">
            <ListItemIcon>
              <CurrencyExchangeIcon />
            </ListItemIcon>
            <ListItemText primary="ปันผล/เฉลี่ยคืน" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/welfare">
            <ListItemIcon>
              <DvrIcon />
            </ListItemIcon>
            <ListItemText primary="สวัสดิการ" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/bill">
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary="ใบเสร็จประจำเดือน" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/beneficiary">
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="ผู้รับผลประโยชน์" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/changePasswordForm">
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="เปลี่ยนรหัสผ่าน" />
          </ListItemButton>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItemButton>
        </List>
      </Drawer>
      {component}
    </Box>
  );
}
