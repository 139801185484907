import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  Avatar,
  Toolbar,
  Box,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { NevComponent } from "../../components/NevComponent";
import DepositDetailModal from "./DepositDetailModal";
import { apiURL } from "../../app/config";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Deposit() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountDetails, setAccountDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchDepositData();
    }
  }, [authData, navigate]);

  const fetchDepositData = async () => {
    try {
      const response = await axios.get(`${apiURL}getDeposit/${authData.membershipNumber}`);
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => ({
          accountNumber: item.AccNo,
          nameAccount: item.AccName,
          date: formatThaiDate(item.AccOpenDate),
          balance: formatNumberWithCommas(item.AccBalAmt),
          interest: formatNumberWithCommas(item.AccAccuInt),
          status: item.AccRecSts || "ปกติ",
          accountType: item.RateDesc,
        }));
        setAccountDetails(formattedData);
      }
    } catch (error) {
      console.error("Error fetching deposit data:", error);
    }
  };

  const fetchDepositDetails = async (accountNumber) => {
    try {
      const response = await axios.get(`${apiURL}getDepositDetails/${accountNumber}`);
      if (response.data.code === 200 && !response.data.error) {
        return response.data.data.map((item) => ({
          date: formatThaiDate(item.StmDate),
          time: item.StmTime,
          code: item.StmCode,
          description: item.CodeAbbre,
          amount1: parseFloat(item.StmAmt1) > 0 ? formatNumberWithCommas(item.StmAmt1) : "-",
          amount2: parseFloat(item.StmAmt2) > 0 ? formatNumberWithCommas(item.StmAmt2) : "-",
          accumulatedInterest: formatNumberWithCommas(item.StmAccuInt),
          oldBalance: formatNumberWithCommas(item.StmOldBal),
          status: item.StmRecSts,
        }));
      }
      return [];
    } catch (error) {
      console.error("Error fetching deposit details:", error);
      return [];
    }
  };

  const formatThaiDate = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const yearOffset = 543;
    const date = new Date(
      dateString.substring(0, 4) +
        "-" +
        dateString.substring(4, 6) +
        "-" +
        dateString.substring(6, 8)
    );
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear() + yearOffset;
    return `${day} ${month} ${year}`;
  };

  
  const handleOpenModal = async (account) => {
    setSelectedAccount(account);
    const depositDetails = await fetchDepositDetails(account.accountNumber);
    setTransactions(depositDetails);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <NevComponent
      title="เงินฝาก"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ p: 3 }}>
                  {accountDetails.map((details, index) => (
                    <Paper
                      sx={{ p: 2, mb: 2, backgroundColor: "#e0f7fa" }}
                      key={index}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar sx={{ backgroundColor: "#4caf50" }}>
                            <AccountBalanceIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body1">
                            ประเภทเงินฝาก : {details.accountType} (คุณ{" "}
                            {details.nameAccount})
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ mt: 2 }}
                      >
                        <Grid item xs={6} sm={4}>
                          <Typography variant="body2">
                            เลขที่บัญชี : {details.accountNumber}
                          </Typography>
                          <Typography variant="body2">
                            ดอกเบี้ยสะสม : {details.interest}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="body2">
                            ชื่อบัญชี : {details.nameAccount}
                          </Typography>
                          <Typography variant="body2">
                            สถานะ : {details.status}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="body2">
                            ยอดคงเหลือ : {details.balance}
                          </Typography>
                          <Button
                            variant="outlined"
                            endIcon={<AccountBalanceIcon />}
                            onClick={() => handleOpenModal(details)}
                          >
                            รายละเอียด
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>

          {isModalOpen && selectedAccount ? (
            <DepositDetailModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              transactions={transactions}
              accountDetails={selectedAccount}
            />
          ) : null}
        </Box>
      }
    />
  );
}
