import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Infouser from "./features/infouser/Infouser";
import Register from "./features/register/Register";
import Welfare from "./features/welfare/Welfare";
import Users from "./features/users/Users";
import Login from "./features/login/Login";
import ShareRegister from "./features/shareRegister/ShareRegister";
import Loan from "./features/loan/Loan";
import Deposit from "./features/deposit/Deposit";
import Collection from "./features/collection/Collection";
import Guarantee from "./features/guarantee/Guarantee";
import Dividend from "./features/dividend/Dividend";
import ChangePasswordForm from "./features/changePasswordForm/ChangePasswordForm";
import ResetChangePassword from "./features/resetChangePassword/ResetChangePassword";

import Bill from "./features/bill/Bill";
import Beneficiary from "./features/beneficiary/Beneficiary";
 
import ResetPassword from "./features/resetPassword/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
          <Route
          path="/reset-change-password"
          element={
            <PublicRoute>
              <ResetChangePassword />
            </PublicRoute>
          }
        />

        <Route
          path="/infouser"
          element={
            <ProtectedRoute>
              <Infouser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shareRegister"
          element={
            <ProtectedRoute>
              <ShareRegister />
            </ProtectedRoute>
          }
        />
        <Route
          path="/loan"
          element={
            <ProtectedRoute>
              <Loan />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <Deposit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/collection"
          element={
            <ProtectedRoute>
              <Collection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guarantee"
          element={
            <ProtectedRoute>
              <Guarantee />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dividend"
          element={
            <ProtectedRoute>
              <Dividend />
            </ProtectedRoute>
          }
        />

        <Route
          path="/welfare"
          element={
            <ProtectedRoute>
              <Welfare />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bill"
          element={
            <ProtectedRoute>
              <Bill />
            </ProtectedRoute>
          }
        />
        <Route
          path="/beneficiary"
          element={
            <ProtectedRoute>
              <Beneficiary />
            </ProtectedRoute>
          }
        />

        <Route
          path="/changePasswordForm"
          element={
            <ProtectedRoute>
              <ChangePasswordForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />

        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

function AccessDenied() {
  return <>Access Denied!</>;
}

function NotFound() {
  return <>Not Found!</>;
}

export default App;
