import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../app/config";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import {
  Container,
  Card,
  CardContent,
  Avatar,
  Typography,
  Button,
  Grid,
  CardHeader,
  Input,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import LoanIcon from "@mui/icons-material/MonetizationOn";
import InvestmentIcon from "@mui/icons-material/ShowChart";
import ScheduleIcon from "@mui/icons-material/Event";
import { NevComponent } from "../../components/NevComponent";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Infouser() {
  const navigate = useNavigate();

  const [authData, setAuthData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [userData, setUserData] = useState(null);
  const [sumLoanProm, setSumLoanProm] = useState(0);
  const [sumSavingMas, setSumSavingMas] = useState(0);
  const [memShrAmt, setMemShrAmt] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      // Fetch user information
      axios
        .get(`${apiURL}getInfouser/${authData.membershipNumber}`)
        .then((response) => {
          const data = response.data;
          if (data.code === 200 && !data.error) {
            setUserData(data.data[0]);
            setMemShrAmt(data.data[0].MemShrAmt);
            // Set image preview URL from API if available
            setPreviewImage(`${apiURL}${data.data[0].imageUrl}`);
          } else {
            console.error("Failed to fetch user data:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });

      // Fetch sumLoanProm
      axios
        .get(`${apiURL}sumLoanProm/${authData.membershipNumber}`)
        .then((response) => {
          const data = response.data;
          if (data.code === 200 && !data.error) {
            setSumLoanProm(data.data);
          } else {
            console.error("Failed to fetch loan data:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching loan data:", error);
        });

      // Fetch sumSavingMas
      axios
        .get(`${apiURL}sumSavingMas/${authData.membershipNumber}`)
        .then((response) => {
          const data = response.data;
          if (data.code === 200 && !data.error) {
            setSumSavingMas(data.data);
          } else {
            console.error("Failed to fetch saving data:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching saving data:", error);
        });
    }
  }, [authData, navigate]);

  const formatThaiDate = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const year = parseInt(dateString.substring(0, 4), 10) + 543;
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);
    return `${day} ${months[month - 1]} ${year}`;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Generate preview URL
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        `${apiURL}user/upload-image`, // Ensure this matches your backend route
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authData.accessToken}`, // Include token in Authorization header
          },
        }
      );

      if (response.data.code === 200 && !response.data.error) {
        // Update user data with new image URL

        console.log(response);
        setUserData((prevUserData) => ({
          ...prevUserData,
          imageUrl: response.data.data.imageUrl,
        }));

        // Update preview image with new URL
        setPreviewImage(`${apiURL}${response.data.data.imageUrl}`);
        setSelectedFile(null)
      } else {
        console.error("Failed to upload image:", response.data);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const items = [
    {
      icon: <MoneyIcon style={{ fontSize: 50, color: "#4caf50" }} />,
      label: "เงินฝาก",
      value: formatNumberWithCommas(sumSavingMas),
      link: "/deposit",
    },
    {
      icon: <LoanIcon style={{ fontSize: 50, color: "#e57373" }} />,
      label: "เงินกู้",
      value: formatNumberWithCommas(sumLoanProm),
      link: "/loan",
    },
    {
      icon: <InvestmentIcon style={{ fontSize: 50, color: "#ffb74d" }} />,
      label: "ทุนเรือนหุ้น",
      value: formatNumberWithCommas(memShrAmt),
      link: "/shareRegister",
    },
    {
      icon: <ScheduleIcon style={{ fontSize: 50, color: "#64b5f6" }} />,
      label: "",
      value: "พิมพ์ใบเสร็จประจำเดือน",
      link: "/bill",
    },
  ];

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <NevComponent
      title="ข้อมูลส่วนบุคคล"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Card>
                    <CardHeader
                      title={
                        <Typography variant="h6" align="left">
                          คุณ {userData.MemName}
                        </Typography>
                      }
                      sx={{ backgroundColor: "#f8e1f1" }}
                    />
                    <CardContent style={{ padding: "20px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} style={{ textAlign: "center" }}>
                          <Avatar
                            src={
                              previewImage || `${apiURL}${userData.imageUrl}`
                            } // Display the preview image if available
                            style={{
                              margin: "0 auto",
                              width: "100px",
                              height: "100px",
                              backgroundColor: "#c5cae9",
                            }}
                          />
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            id="image-upload"
                          />
                          <Button
                            variant="contained"
                            startIcon={<UploadIcon />}
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#e1bee7",
                              color: "black",
                            }}
                            onClick={() =>
                              document.getElementById("image-upload").click()
                            }
                          >
                            เลือกรูปภาพ
                          </Button>
                          {selectedFile ? (
                            <Button
                              variant="contained"
                              style={{
                                marginTop: "10px",
                                backgroundColor: "#e1bee7",
                                color: "black",
                              }}
                              onClick={handleImageUpload}
                            >
                              อัพโหลดรูปส่วนตัว
                            </Button>
                          ) : null}
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>ทะเบียนสมาชิก:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemID}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>เลขบัตรประชาชน:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemCardID}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>วันเกิด:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {formatThaiDate(userData.MemBDay)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>สถานะภาพ:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.StsTypeDesc}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>ที่อยู่ตามทะเบียนบ้าน:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemAddr}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>ที่อยู่ส่งเอกสาร:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemAddr2}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>เบอร์มือถือ:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemMobile}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>อีเมล:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemEmail}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>วันที่เป็นสมาชิก:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {formatThaiDate(userData.MemYmdIn)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>อายุการเป็นสมาชิก:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.MemShrPerd}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>หน่วยงาน:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {userData.DeptName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>เงินเดือน:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {formatNumberWithCommas(userData.MemSalary)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>เงินประจำตำแหน่ง:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {formatNumberWithCommas(
                                      userData.MemPositionAmt
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="body1">
                                    <strong>หุ้นรายเดือน:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body1">
                                    {formatNumberWithCommas(
                                      userData.MemMonthShrAmt
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              {items.map((item, index) => (
                <Grid item xs={12} md={4} lg={3} key={index}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: 240,
                      justifyContent: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(item.link)}
                  >
                    <Avatar
                      sx={{
                        width: 70,
                        height: 70,
                        mb: 2,
                        backgroundColor: "#f0f4c3",
                      }}
                    >
                      {item.icon}
                    </Avatar>
                    <Typography variant="body1">{item.label}</Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      {item.value}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
