import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const DepositDetailModal = ({
  isOpen,
  onClose,
  transactions,
  accountDetails,
}) => {
  const handlePrint = () => {
    const printContents = document.getElementById("printTable").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to restore the original contents
  };

  const calculateBalance = (oldBalance, amount, type) => {
    const parsedOldBalance = parseFloat(oldBalance.replace(/,/g, ''));
    const parsedAmount = parseFloat(amount.replace(/,/g, ''));
    if (type === 'deposit') {
      return (parsedOldBalance + parsedAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else if (type === 'withdraw') {
      return (parsedOldBalance - parsedAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return oldBalance;
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>รายละเอียดการฝาก</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: "#ff9800",
                }}
                onClick={handlePrint}
              >
                พิมพ์
              </Button>
            </Grid>
          </Grid>
          <Box id="printTable" sx={{ p: 3 }}>
            <Paper sx={{ p: 2, mb: 2, backgroundColor: "#e0f7fa" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#4caf50" }}>
                    <AccountBalanceIcon />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">
                    ประเภทเงินฝาก : {accountDetails.accountType} (คุณ{" "}
                    {accountDetails.nameAccount})
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    เลขที่บัญชี : {accountDetails.accountNumber}
                  </Typography>
                  <Typography variant="body2">
                    ยอดที่ถอนได้ : {accountDetails.balance}
                  </Typography>
                  <Typography variant="body2">สมุดฝาก : -</Typography>
                  <Typography variant="body2">
                    ชื่อบัญชี : {accountDetails.nameAccount}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    วันที่เปิดบัญชี : {accountDetails.date}
                  </Typography>
                  <Typography variant="body2">
                    ยอดคงเหลือ : {accountDetails.balance}
                  </Typography>
                  <Typography variant="body2">
                    สถานะ : {accountDetails.status}
                  </Typography>
                  <Typography variant="body2">
                    ดอกเบี้ยสะสม : {accountDetails.interest}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ p: 2, backgroundColor: "#e0f2f1" }}>
              <Typography variant="h6">รายการเคลื่อนไหว</Typography>
              <Divider sx={{ my: 2 }} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>วันที่</TableCell>
                      <TableCell>รายการ</TableCell>
                      <TableCell align="center">ฝาก</TableCell>
                      <TableCell align="center">ถอน</TableCell>
                      <TableCell align="center">ยอดคงเหลือ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((transaction, index) => {
                      const isDeposit = transaction.description === "DTR";
                      const balance = calculateBalance(transaction.oldBalance, transaction.amount1, isDeposit ? 'deposit' : 'withdraw');
                      return (
                        <TableRow key={index}>
                          <TableCell>{transaction.date} {transaction.time}</TableCell>
                          <TableCell>{transaction.description}</TableCell>
                          <TableCell align="center">
                            {isDeposit ? transaction.amount1 : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {isDeposit ? "-" : transaction.amount1}
                          </TableCell>
                          <TableCell align="center">
                            {balance}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Typography variant="body2">
                  รายการข้อมูลทั้งหมด {transactions.length} รายการ
                </Typography>
              </Grid>
            </Paper>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#6a1b9a" }}
          >
            ปิด
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DepositDetailModal;
