import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Toolbar,
} from "@mui/material";
import { NevComponent } from "../../components/NevComponent";
import { apiURL } from "../../app/config";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Welfare() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [welfareDetails, setWelfareDetails] = useState([]);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchWelfareData();
    }
  }, [authData, navigate]);

  const fetchWelfareData = async () => {
    try {
      const response = await axios.get(
        `${apiURL}getWelfare/${authData.membershipNumber}`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => ({
          date: formatThaiDate(item.StmDate),
          description: item.WfName,
          amount: formatNumberWithCommas(item.StmAmt),
        }));
        setWelfareDetails(formattedData);
      }
    } catch (error) {
      console.error("Error fetching welfare data:", error);
    }
  };

  const formatThaiDate = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const yearOffset = 543;
    const date = new Date(
      dateString.substring(0, 4) +
        "-" +
        dateString.substring(4, 6) +
        "-" +
        dateString.substring(6, 8)
    );
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear() + yearOffset;
    return `${day} ${month} ${year}`;
  };

 
  return (
    <NevComponent
      title="สวัสดิการ"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, backgroundColor: "#f3e5f5" }}>
                  <Typography
                    variant="h6"
                    sx={{ backgroundColor: "#f3e5f5", p: 1 }}
                  >
                    สวัสดิการ คุณ {authData.fullName}
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#e1bee7" }}>
                        <TableRow>
                          <TableCell>วันที่</TableCell>
                          <TableCell>รายการ</TableCell>
                          <TableCell align="right">จ่าย</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {welfareDetails.length > 0
                          ? welfareDetails.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell align="right">
                                  {row.amount}
                                </TableCell>
                              </TableRow>
                            ))
                          : "ไม่มีข้อมูล"}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
