import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const isAuthenticated = !!JSON.parse(localStorage.getItem("authData")); // Check for token in localStorage or any other storage

  return !isAuthenticated ? children : <Navigate to="/infouser" />;
};

export default PublicRoute;
