import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../app/config";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Toolbar,
} from "@mui/material";
import InvestmentIcon from "@mui/icons-material/ShowChart";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { NevComponent } from "../../components/NevComponent";
import YearSelect from "../../components/YearSelect";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function ShareRegister() {
  const replace = useNavigate();
  const [year, setYear] = useState("all");
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log("useEffect");

    if (!authData) {
      replace("/login");
    } else {
      console.log("fetchData");
      fetchData();
    }
  }, [authData, year, replace]);

  const formatThaiDate = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const yearOffset = 543;
    const date = new Date(
      dateString.substring(0, 4) +
        "-" +
        dateString.substring(4, 6) +
        "-" +
        dateString.substring(6, 8)
    );
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear() + yearOffset;
    return `${day} ${month} ${year}`;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiURL}getShareRegister/${authData.membershipNumber}${
          year === "all" ? "" : `?StmYear=${year}`
        }`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = response.data.data.map((item) => ({
          id: item.StmDocNo, // Assuming StmDocNo is unique
          date: formatThaiDate(item.StmDate),
          detail: `${item.StmPerd ? `งวดที่ ${item.StmPerd}` : "ยกมา"}`,
          value: item.StmPerd ? formatNumberWithCommas(item.StmCrAmt) : "0.00",
          total: item.StmPerd ? formatNumberWithCommas(item.StmOldBal) : formatNumberWithCommas(item.StmCrAmt),
          billNumber: item.StmBiLLDocNo ? formatString(item.StmBiLLDocNo) : "-",
        }));

        console.log(formattedData);
        setRows(formattedData);
      }
    } catch (error) {
      setRows([]);
      console.error("Error fetching data:", error);
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    // Add logic to refetch data based on the selected year if needed
  };
 
  function formatString(input) {
    // Extract year, middle part, and last part
    const yearPart = input.slice(0, 4);
    const middlePart = input.slice(4, 6);
    const lastPart = input.slice(6);
  
    // Format the year to the desired format (subtract 543 for Thai Buddhist calendar)
    const formattedYear = (parseInt(yearPart, 10) + 543).toString();
  
    // Combine the parts into the desired format
    const formattedString = `M${middlePart.charAt(1)}-${lastPart}/${formattedYear}`;
  
    return formattedString;
  }

  const handlePrint = () => {
    const printContents = document.getElementById("printTable").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to restore the original contents
  };

  return (
    <NevComponent
      title="ทะเบียนหุ้น"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar sx={{ backgroundColor: "#ff9800" }}>
                        <InvestmentIcon />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">ทุนเรือนหุ้น</Typography>
                    </Grid>
                  </Grid>

                  {rows.length > 0 ? (
                    <div>
                      <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                        ทุนเรือนหุ้นสะสม(บาท) : <strong>{rows[0].total}</strong>
                      </Typography>
                      <Typography variant="body1" align="center">
                        หุ้นรายเดือน(บาท) : <strong>{rows[0].value}</strong>
                      </Typography>
                      <Typography variant="body1" align="center">
                        งวดล่าสุด(บาท) : <strong>{rows[0].value}</strong>
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}

                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item>
                      <CalendarTodayIcon />
                    </Grid>
                    <Grid item>
                      <Typography>เลือกดูรายการของ</Typography>
                    </Grid>
                    <Grid item>
                      <YearSelect
                        year={year}
                        handleYearChange={handleYearChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 2,
                          backgroundColor: "#ff9800",
                        }}
                        onClick={handlePrint}
                      >
                        พิมพ์
                      </Button>
                    </Grid>
                  </Grid>

                  <TableContainer
                    id="printTable"
                    component={Paper}
                    sx={{ mt: 2 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#ffd18b" }}>
                          <TableCell align="center">วันที่</TableCell>
                          <TableCell align="center">รายละเอียด</TableCell>
                          <TableCell align="right">จำนวนเงิน(บาท)</TableCell>
                          <TableCell align="right">หุ้นคงเหลือ(บาท)</TableCell>
                          <TableCell align="right">เลขที่ใบเสร็จ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.length > 0
                          ? rows.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">
                                  {row.detail}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                                <TableCell align="right">
                                  {row.billNumber}
                                </TableCell>
                              </TableRow>
                            ))
                          : "ไม่มีทุนเรือนหุ้น"}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
