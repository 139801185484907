import * as React from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../../services/auth.service";
import { useDispatch } from "react-redux";
import { setAuth } from "./authSlice";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel"; 

export default function Login() {
  const navigate = useNavigate();
  const [showErrorUsername, setShowErrorUsername] = React.useState("");
  const [showErrorPassword, setShowErrorPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);

  const replace = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const dataLogin = {
      username: data.get("username"),
      password: data.get("password"),
    };
    setShowErrorUsername("");
    setShowErrorPassword("");
    if (!dataLogin.username || !dataLogin.password) {
      if (!dataLogin.username) {
        setShowErrorUsername("Please insert your Username");
      }
      if (!dataLogin.password) {
        setShowErrorPassword("Please insert your Password");
      }
      return;
    }

    try {
      const reqLogin = await authLogin({
        username: dataLogin.username,
        password: dataLogin.password,
      });
      if (!reqLogin.error && reqLogin.error !== null) {
        if (reqLogin.error || !reqLogin.data) {
          if (reqLogin.message) {
            alert(reqLogin.message);
          } else {
            alert("Something wrong!");
          }
        } else { 
          console.log(reqLogin)
          dispatch(setAuth(reqLogin.data));
          localStorage.setItem("authData", JSON.stringify(reqLogin.data));
          replace("/infouser");
        }
      } else {
        if (reqLogin.message) {
          alert(reqLogin.message);
        } else {
          alert("Failed");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  var errorUsername = showErrorUsername ? { error: true } : {};
  var errorPassword = showErrorPassword ? { error: true } : {};
  return (
    
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ backgroundColor: "#37E4FF" }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{ borderRadius: "10px" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 100, height: 100, borderRadius: 0 }}
              src="/LogoICT.png"
            />
            <Typography component="h1" variant="h5" className="mt-2">
              ระบบข้อมูลสมาชิก
            </Typography> 
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="เลขที่สมาชิก"
                name="username"
                autoComplete="username"
                autoFocus
                helperText={showErrorUsername}
                {...errorUsername}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="รหัสผ่าน"
                type="password"
                id="password"
                helperText={showErrorPassword}
                autoComplete="current-password"
                {...errorPassword}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    name="rememberMe"
                    color="primary"
                  />
                }
                label="ให้ฉันอยู่ในระบบ"
              />
              <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3, 
                mb: 2, 
                bgcolor: "transparent",
                width: '3/6', 
                md: { width: '1/4' }, 
                lg: { width: '1/4' }, 
                xl: { width: '1/4' },
                backgroundImage: "linear-gradient(to right, #B645CA, #B1073F)",
                "&:hover": {
                  backgroundImage: "linear-gradient(to right, #B645CA, #B1073F)",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                  transform: "translateY(-1px)",
                  transition: "all 0.3s ease-in-out",
                }
              }}
            >
              เข้าสู่ระบบ
            </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "#771E61" }} 
                    onClick={() => navigate("/register")}

                  >
                    สมัครสมาชิก
                  </Button>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "#B1073F" }} 
                    style={{ float: "right" }}
                    onClick={() => navigate("/reset-password")}
                  >
                    ลืมรหัสผ่าน ?
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid> 
  );
}
