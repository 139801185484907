import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff, Lock } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import { NevComponent } from "../../components/NevComponent";
import { apiURL } from "../../app/config";

export default function ResetChangePassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tokenFromURL = searchParams.get("token");
    if (tokenFromURL) {
      setToken(tokenFromURL);
    } else {
      navigate("/login");
    }
  }, [navigate, searchParams]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => () => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.newPassword !== values.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(`${apiURL}auth/resetPassword`, {
        token: token,
        newPassword: values.newPassword,
      });

      if (response.status === 200) {
        alert("Password reset successfully");
        navigate("/login");
      } else {
        handleErrors(response);
      }
    } catch (error) {
      handleErrors(error.response);
    }
  };

  const handleErrors = (response) => {
    if (response.data.code === 400) {
      setError("Invalid token or token expired.");
    } else {
      setError("An unknown error occurred.");
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ p: 3 }}>
              <Paper sx={{ p: 4, m: 2, border: "2px solid #f50057" }}>
                <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                  <Lock /> ตั้งรหัสผ่านใหม่
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="รหัสผ่านใหม่"
                        type={values.showNewPassword ? "text" : "password"}
                        value={values.newPassword}
                        onChange={handleChange("newPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword(
                                  "showNewPassword"
                                )}
                                edge="end"
                              >
                                {values.showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="ยืนยันรหัสผ่านใหม่"
                        type={values.showConfirmPassword ? "text" : "password"}
                        value={values.confirmPassword}
                        onChange={handleChange("confirmPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword(
                                  "showConfirmPassword"
                                )}
                                edge="end"
                              >
                                {values.showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Typography color="error" align="center">
                          {error}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} textAlign="center">
                      <Button variant="contained" color="error" type="submit">
                        ตั้งรหัสผ่านใหม่
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
