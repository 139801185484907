import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";

const GuarantorModal = ({ isOpen, onClose, guarantorData }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2, mb: 2, backgroundColor: "#e0f7fa" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#4caf50" }}>
                    <HandshakeIcon />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">ผู้ค้ำประกัน</Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ p: 2, backgroundColor: "#e0f2f1" }}>
              <Typography variant="h6">รายละเอียดผู้ค้ำประกัน</Typography>
              <Divider sx={{ my: 2 }} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>เลขที่สมาชิก</TableCell>
                      <TableCell>ชื่อ-สกุล</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guarantorData.map((guarantor, index) => (
                      <TableRow key={index}>
                        <TableCell>{guarantor.memberId}</TableCell>
                        <TableCell>{guarantor.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#6a1b9a" }}
          >
            ปิด
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GuarantorModal;
