import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  Avatar,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { NevComponent } from "../../components/NevComponent";
import { apiURL } from "../../app/config";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Collection() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [summary, setSummary] = useState({
    totalPrincipal: "0.00",
    totalInterest: "0.00",
    totalAmount: "0.00",
  });

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchCollectionData();
    }
  }, [authData, navigate]);

  const fetchCollectionData = async () => {
    try {
      const response = await axios.get(`${apiURL}getCollection/${authData.membershipNumber}`);
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => ({
          id: item.PayID,
          description: getDescription(item),
          installment: item.PayPerd,
          principal: formatNumberWithCommas(item.PayAmt),
          interest: formatNumberWithCommas(item.PayInt),
          total: formatNumberWithCommas((parseFloat(item.PayAmt) + parseFloat(item.PayInt)).toFixed(2)),
        }));

        const totalPrincipal = formattedData.reduce((acc, item) => acc + parseFloat(item.principal.replace(/,/g, '')), 0);
        const totalInterest = formattedData.reduce((acc, item) => acc + parseFloat(item.interest.replace(/,/g, '')), 0);
        const totalAmount = totalPrincipal + totalInterest;

        setCollectionDetails(formattedData);
        setSummary({
          totalPrincipal: formatNumberWithCommas(totalPrincipal.toFixed(2)),
          totalInterest: formatNumberWithCommas(totalInterest.toFixed(2)),
          totalAmount: formatNumberWithCommas(totalAmount.toFixed(2)),
        });
      }
    } catch (error) {
      console.error("Error fetching collection data:", error);
    }
  };

  const getDescription = (item) => {
    if (item.PayLoanType === "0") {
      return "ค่าหุ้น";
    } else if (item.PayLoanType === "1" || item.PayLoanType === "2" || item.PayLoanType === "3") {
      const promNoPart1 = item.PayPromNo.substring(5, 10);
      const promNoPart2 = (parseInt(item.PayPromNo.substring(1, 4)) + 543).toString();
      return `สจ-${promNoPart1}/${promNoPart2}`;
    } else if (item.PayLoanType === "7" || item.PayLoanType === "8") {
      return item.PayDesc;
    } else if (item.PayLoanType === "9") {
      return item.PayPromNo;
    } else {
      return "รายการไม่ระบุ";
    }
  };


  const handleOpenModal = (details) => {
    setSelectedDetails(details);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <NevComponent
      title="รายการเรียกเก็บประจำเดือน"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, backgroundColor: "#e0f7fa" }}>
                  <Typography variant="h6" gutterBottom>
                    รายการเรียกเก็บประจำเดือน
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>รายการ</TableCell>
                          <TableCell align="right">งวดที่</TableCell>
                          <TableCell align="right">เงินต้น</TableCell>
                          <TableCell align="right">ดอกเบี้ย</TableCell>
                          <TableCell align="right">รวม</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {collectionDetails.map((details, index) => (
                          <TableRow key={index} onClick={() => handleOpenModal(details)} style={{ cursor: 'pointer' }}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{details.description}</TableCell>
                            <TableCell align="right">{details.installment}</TableCell>
                            <TableCell align="right">{details.principal}</TableCell>
                            <TableCell align="right">{details.interest}</TableCell>
                            <TableCell align="right">{details.total}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={3} align="right">
                            <strong>รวม</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>{summary.totalPrincipal}</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>{summary.totalInterest}</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>{summary.totalAmount}</strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Container>

          <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
            <DialogTitle>รายละเอียดการเรียกเก็บ</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>รายการ</TableCell>
                      <TableCell align="right">งวดที่</TableCell>
                      <TableCell align="right">เงินต้น</TableCell>
                      <TableCell align="right">ดอกเบี้ย</TableCell>
                      <TableCell align="right">รวม</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDetails && (
                      <TableRow>
                        <TableCell>{selectedDetails.description}</TableCell>
                        <TableCell align="right">{selectedDetails.installment}</TableCell>
                        <TableCell align="right">{selectedDetails.principal}</TableCell>
                        <TableCell align="right">{selectedDetails.interest}</TableCell>
                        <TableCell align="right">{selectedDetails.total}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                ปิด
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      }
    />
  );
}
