import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Button,
  Avatar,
  Box,
  Toolbar,
} from "@mui/material";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { NevComponent } from "../../components/NevComponent";
import LoanDetailModal from "./LoanDetailModal";
import GuarantorModal from "./GuarantorModal";
import { apiURL } from "../../app/config";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Loan() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGuarantorModalOpen, setIsGuarantorModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [guarantorData, setGuarantorData] = useState([]);
  const [loanDetails, setLoanDetails] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchLoanData();
    }
  }, [authData, navigate]);

  const fetchLoanData = async () => {
    try {
      const response = await axios.get(
        `${apiURL}getLoan/${authData.membershipNumber}`
      );
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => {
          return {
            contractNumber: item.PromNo1,
            contractNumberNo: item.PromNo,
            contractDate: formatThaiDate(item.PromDate),
            loanAmount: formatNumberWithCommas(item.PromLoanAmt),
            condition:
              item.PromCondType === "1"
                ? "เงินต้น+ดอกเบี้ยเท่า"
                : "เงินต้นเท่า",
            outstandingBalance: formatNumberWithCommas(item.PromBalAmt),
            installmentPayment: formatNumberWithCommas(item.PromPerdAmt),
            guarantorData: [],
            transactions: [],
            paymentDetails: [],
          };
        });
        setAccountDetails(formattedData);
      }
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }
  };

  const fetchGuarantorDetails = async (contractNumber) => {
    try {
      const response = await axios.get(
        `${apiURL}getLoanGuarantorDetails/${contractNumber}`
      );
      if (response.data.code === 200 && !response.data.error) {
        return response.data.data.map((item) => ({
          memberId: item.GuarGuarMemID,
          name: `${item.Prename} ${item.MemName}`,
        }));
      }
      return [];
    } catch (error) {
      console.error("Error fetching guarantor details:", error);
      return [];
    }
  };

  const fetchLoanDetails = async (contractNumber) => {
    try {
      const response = await axios.get(
        `${apiURL}getLoanDetails/${contractNumber}`
      );
      if (response.data.code === 200 && !response.data.error) {
        return response.data.data.map((item) => {
          let sum = parseFloat(item.StmDbAmt) + parseFloat(item.StmCrAmt);
          let oldBalance = 0;
          if (item.StmCode == "1") {
            oldBalance = parseFloat(item.StmOldBal) + parseFloat(item.StmCrAmt);
          } else {
            oldBalance = parseFloat(item.StmOldBal) - parseFloat(item.StmCrAmt);
          }

          console.log(sum);
          return {
            docNo: item.StmDocNo,
            year: item.StmYear,
            code: item.StmCode,
            date: formatThaiDate(item.StmDate),
            isCancel: item.StmIsCancel,
            memberId: item.StmMemID,
            period: item.StmCode == "1" ? "รับเงินกู้" : item.StmPerd,
            crAmt: formatNumberWithCommas(item.StmCrAmt),
            creditAmount:
              item.StmCode == "1"
                ? formatNumberWithCommas(item.StmCrAmt)
                : formatNumberWithCommas(sum),
            debitAmount: formatNumberWithCommas(item.StmDbAmt),
            oldBalance: formatNumberWithCommas(oldBalance),
            billDocNo: item.StmBiLLDocNo
              ? formatStringBill(item.StmBiLLDocNo)
              : "",
          };
        });
      }
      return [];
    } catch (error) {
      console.error("Error fetching loan details:", error);
      return [];
    }
  };

  const formatThaiDate = (dateString) => {
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const yearOffset = 543;
    const date = new Date(
      dateString.substring(0, 4) +
        "-" +
        dateString.substring(4, 6) +
        "-" +
        dateString.substring(6, 8)
    );
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear() + yearOffset;
    return `${day} ${month} ${year}`;
  };

  function formatStringBill(input) {
    // Extract year, middle part, and last part
    const yearPart = input.slice(0, 4);
    const middlePart = input.slice(4, 6);
    const lastPart = input.slice(6);

    // Format the year to the desired format (subtract 543 for Thai Buddhist calendar)
    const formattedYear = (parseInt(yearPart, 10) + 543).toString();

    // Combine the parts into the desired format
    const formattedString = `M${middlePart.charAt(
      1
    )}-${lastPart}/${formattedYear}`;

    return formattedString;
  }
  const handleOpenModal = async (account) => {
    setSelectedAccount(account);
    const loanDetails = await fetchLoanDetails(account.contractNumberNo);
    setLoanDetails(loanDetails);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenGuarantorModal = async (account) => {
    setSelectedAccount(account);
    const guarantorDetails = await fetchGuarantorDetails(
      account.contractNumberNo
    );
    setGuarantorData(guarantorDetails);
    setIsGuarantorModalOpen(true);
  };

  const handleCloseGuarantorModal = () => {
    setIsGuarantorModalOpen(false);
  };

  return (
    <NevComponent
      title="เงินกู้"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ p: 3 }}>
                  {accountDetails.map((details, index) => (
                    <Paper
                      sx={{ p: 2, mb: 2, backgroundColor: "#e0f7fa" }}
                      key={index}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 2 }}
                      >
                        <Grid item>
                          <Avatar sx={{ backgroundColor: "#4caf50" }}>
                            <LocalAtmIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">
                            สัญญาเงินกู้ เลขที่ : {details.contractNumber}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6} sm={4}>
                          <Typography variant="body2">
                            วันที่ทำสัญญา : {details.contractDate}
                          </Typography>

                          <Typography variant="body2">
                            วงเงินกู้ : {details.loanAmount}
                          </Typography>

                          <Typography variant="body2">
                            เงื่อนไขการชำระ : {details.condition}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="body2">สถานะ : -</Typography>
                          <Typography variant="body2">
                            หนี้คงเหลือ : {details.outstandingBalance}
                          </Typography>
                          <Typography variant="body2">
                            เงินชำระ/งวด : {details.installmentPayment}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item sx={{ ml: "auto" }}>
                          <Button
                            variant="outlined"
                            endIcon={<HandshakeIcon />}
                            onClick={() => handleOpenGuarantorModal(details)}
                            style={{ marginRight: "5px" }}
                          >
                            ผู้ค้ำประกัน
                          </Button>
                          <Button
                            variant="outlined"
                            endIcon={<LocalAtmIcon />}
                            onClick={() => handleOpenModal(details)}
                          >
                            รายละเอียด
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>

          {isModalOpen && selectedAccount ? (
            <LoanDetailModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              transactions={loanDetails}
              accountDetails={selectedAccount}
              paymentDetails={selectedAccount.paymentDetails}
            />
          ) : null}

          {isGuarantorModalOpen && guarantorData.length > 0 ? (
            <GuarantorModal
              isOpen={isGuarantorModalOpen}
              onClose={handleCloseGuarantorModal}
              guarantorData={guarantorData}
            />
          ) : null}
        </Box>
      }
    />
  );
}
