import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const LoanDetailModal = ({ isOpen, onClose, transactions, accountDetails }) => {
  const handlePrint = () => {
    const printContents = document.getElementById("printTable").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to restore the original contents
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>รายละเอียดการกู้ยืม</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: "#ff9800",
                }}
                onClick={handlePrint}
              >
                พิมพ์
              </Button>
            </Grid>
          </Grid>
          <Box id="printTable" sx={{ p: 3 }}>
            <Paper sx={{ p: 2, mb: 2, backgroundColor: "#e0f7fa" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar sx={{ backgroundColor: "#4caf50" }}>
                    <AccountBalanceIcon />
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">
                    สัญญาเงินกู้ เลขที่ ({accountDetails.contractNumber})
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    วันที่ทำสัญญา : {accountDetails.contractDate}
                  </Typography>
                  <Typography variant="body2">
                    วงเงินกู้ : {accountDetails.loanAmount}
                  </Typography>
                  <Typography variant="body2">
                    เงื่อนไขการชำระ : {accountDetails.condition}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    สถานะ : {accountDetails.status || "-"}
                  </Typography>
                  <Typography variant="body2">
                    หนี้คงเหลือ : {accountDetails.outstandingBalance}
                  </Typography>
                  <Typography variant="body2">
                    เงินชำระ/งวด : {accountDetails.installmentPayment}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ p: 2, backgroundColor: "#e0f2f1" }}>
              <Typography variant="h6">รายละเอียดการชำระหนี้</Typography>
              <Divider sx={{ my: 2 }} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>วันที่</TableCell>
                      <TableCell align="right">งวด</TableCell>
                      <TableCell align="right">เงินต้น</TableCell>
                      <TableCell align="right">ดอกเบี้ย</TableCell>
                      <TableCell align="right">รวมชำระ</TableCell>
                      <TableCell align="right">หนี้คงเหลือ</TableCell>
                      <TableCell align="right">เลขที่ใบเสร็จ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((transaction, index) => (
                      <TableRow key={index}>
                        <TableCell>{transaction.date}</TableCell>
                        <TableCell align="right">{transaction.period}</TableCell>
                        <TableCell align="right">{transaction.crAmt}</TableCell>
                        <TableCell align="right">{transaction.debitAmount}</TableCell>
                        <TableCell align="right">{transaction.period == "รับเงินกู้" ? "0.00" : transaction.creditAmount}</TableCell>
                        <TableCell align="right">{transaction.oldBalance}</TableCell>
                        <TableCell align="right">{transaction.billDocNo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#6a1b9a" }}
          >
            ปิด
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LoanDetailModal;
