import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Container,
  Typography,
  Grid,
  Box,
  Toolbar,
} from "@mui/material";
import { NevComponent } from "../../components/NevComponent";
import { apiURL } from "../../app/config";
import { formatNumberWithCommas } from "../../components/formatNumberWithCommas";

export default function Dividend() {
  const navigate = useNavigate();
  const [authData] = useState(
    JSON.parse(localStorage.getItem("authData")) || false
  );
  const [dividends, setDividends] = useState([]);

  useEffect(() => {
    if (!authData) {
      navigate("/login");
    } else {
      fetchDividendData();
    }
  }, [authData, navigate]);

  const fetchDividendData = async () => {
    try {
      const response = await axios.get(`${apiURL}getDividends/${authData.membershipNumber}`);
      const data = response.data;
      if (data.code === 200 && !data.error) {
        const formattedData = data.data.map((item) => ({
          year: (parseInt(item.DD_Year) + 543).toString(),  // แปลงปี ค.ศ. เป็นปี พ.ศ.
          dividendRate: item.DD_DivdRate,
          refundRate: item.DD_RetRate,
          dividend: formatNumberWithCommas(item.DD_DivdAmt),
          refund: formatNumberWithCommas(item.DD_Retamt),
          total: formatNumberWithCommas((parseFloat(item.DD_DivdAmt) + parseFloat(item.DD_Retamt)).toFixed(2)),
        }));
        formattedData.sort((a, b) => b.year - a.year);
        setDividends(formattedData);
      }
    } catch (error) {
      console.error("Error fetching dividend data:", error);
    }
  };
   
  return (
    <NevComponent
      title="ปันผล/เฉลี่ยคืน"
      component={
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ p: 3 }}>
                  {dividends.map((item, index) => (
                    <Paper key={index} sx={{ p: 2, mb: 2, backgroundColor: "#f3e5f5" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <Box
                            sx={{
                              p: 2,
                              backgroundColor: "#cbe8fd",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography variant="h5" color="primary">
                              {item.year}
                            </Typography>
                            <Typography variant="body1" color="primary">
                              รวมจ่าย
                            </Typography>
                            <Typography variant="h6" color="primary">
                              {item.total} ฿
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography>เงินปันผล(บาท) :</Typography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <Typography>{item.dividend}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>เงินเฉลี่ยคืน(บาท) :</Typography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <Typography>{item.refund}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>
                                  <strong>รวม(บาท) :</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <Typography>
                                  <strong>{item.total}</strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
    />
  );
}
