import React from 'react';
import { Grid, Select, MenuItem } from '@mui/material';

const YearSelect = ({ year, handleYearChange }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (v, i) => currentYear - i); // Generate an array of years from now to 20 years ago

  return (
    <Grid item>
      <Select
        value={year}
        onChange={handleYearChange}
        displayEmpty
      >
        <MenuItem value="all">ทั้งหมด</MenuItem>
        {years.map((yr) => (
          <MenuItem key={yr} value={yr}>{yr}</MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default YearSelect;
